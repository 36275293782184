<template>
    <!-- CE Form group -->
    <div class="form-group">
        <label class="form-label" for="dachziegelmodell">
            Dachziegelmodell
        </label>
        <select class="form-select" aria-label="Default select example" id="dachziegelmodell" name="dachziegelmodell" v-model="tile">
            <option v-for="modell in tiles" :key="modell.post_name_id" :value="modell" selected>{{ modell.titel }}</option>
        </select>
    </div>
    <!-- CE Form group -->

    <!-- CE Product teaser -->
    <div class="ce-product-teaser" v-if="tile">
        <div class="ce-product-teaser__image">
            <img class="img-fluid" :src="tile.img.url" :srcset="`${ tile.img.url } 1x, ${ tile.img.urlx2 } 2x`" width="140" height="140" alt="" />
        </div>
        <div class="ce-product-teaser__content">
            <h3 class="ce-product-teaser__content__title">{{ tile.titel }}</h3>
            <ul class="ce-product-teaser__content__attributes" v-html="getHagelwiderstandsklasse() ">
            </ul>
        </div>
    </div>
    <!-- CE Product teaser -->
</template>

<script>
import {mapFields} from "vuex-map-fields";

export default {
    name: "SelectDachziegel",
    computed: {
        ...mapFields({
            tiles: 'content.ziegel',
            tile: 'tiles.selectedTile'
        })
    },
    methods: {
        getHagelwiderstandsklasse() {
            return this.tile.hagelwiderstandsklasse ? '<li>Hagelwiderstandsklasse ' + this.tile.hagelwiderstandsklasse : '</li>';
        }
    },
}
</script>

<style scoped>

</style>