import { createWebHistory, createRouter } from "vue-router";

import Home from "@/views/ViewHome";
import Standort from "@/views/ViewStandort";
import Objekt from "@/views/ViewObjekt";
import Schneeschutzsystem from "@/views/ViewSchneeschutzsystem";
import Ergebnis from "@/views/ViewErgebnis";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/standort",
        name: "Standort",
        component: Standort,
    },
    {
        path: "/objekt",
        name: "Objekt",
        component: Objekt,
    },
    {
        path: "/schneeschutzsystem",
        name: "Schneeschutzsystem",
        component: Schneeschutzsystem,
    },
    {
        path: "/ergebnis",
        name: "Ergebnis",
        component: Ergebnis,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});

export default router;
