<template>
  <main id="main" class="page-main">
    <tpl-stepper/>
    <div class="block block-form">
      <div class="container">
        <div class="columns columns-4-4 row">
          <div class="column col-10 offset-1 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5">
            <!-- CE Content -->
            <div class="ce-content" v-html="this.schneelr_schneeschutzsystem"></div>
            <!-- CE Content -->
            <!-- CE Form group -->
            <div class="ce-form-group">
              <div class="image-radios">
                <div class="row">
                  <div class="col-12 col-md-6"
                       v-for="(schneeschutzsystemOption, index) in this.schneelr_schneeschutzsystem_select"
                       :key="index">
                    <div class="image-radios__item">
                      <input class="image-radios__input" type="radio" name="test"
                             :id="`schneeschutzsystemradio-${ index }`" :value="schneeschutzsystemOption"
                             v-model="schneeschutzsystem"/>
                      <label class="image-radios__label" :for="`schneeschutzsystemradio-${ index }`">
                        <img class="image-radios__label-image" :src="schneeschutzsystemOption.image.url"
                             :srcset="`${ schneeschutzsystemOption.image.url } 1x, ${ schneeschutzsystemOption.image.urlx2 } 2x`"
                             width="210" height="150" alt=""/>
                        <span class="image-radios__label-text">{{ schneeschutzsystemOption.text }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- CE Form group -->
          </div>
          <div class="col-10 offset-1 offset-lg-1 col-lg-5 offset-xl-1 col-xl-4">
            <!-- CE Content -->
            <div class="ce-content" v-html="this.schneelr_sicherung"></div>
            <!-- CE Content -->
            <!-- CE Form group -->
            <div class="ce-form-group">
            </div>
            <!-- CE Form group -->
            <!-- CE Image -->
            <div class="ce-image ce-image--center">
              <img class="img-fluid" :src="schneelr_schneeschutzsystem_img_r.url"
                   :srcset="schneelr_schneeschutzsystem_img_r.url" width="394" height="360" alt=""/>
            </div>
            <!-- CE Image -->
          </div>
        </div>
      </div>
    </div>
  </main>
  <tpl-footer active="Objekt" :validation="v$"/>
</template>
<script>
import TplFooter from "@/components/TplFooter";
import TplStepper from "@/components/TplStepper";
import {mapFields} from "vuex-map-fields";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";

export default {
  name: "ViewSchneeschutzsystem",
  components: {TplFooter, TplStepper},
  computed: {
    // The `mapFields` function takes an array of
    // field names and generates corresponding
    // computed properties with getter and setter
    // functions for accessing the Vuex store.
    ...mapFields({
      schneelr_schneeschutzsystem: 'content.options.schneeschutzsystem.schneelr_schneeschutzsystem',
      schneelr_sicherung: 'content.options.schneeschutzsystem.schneelr_sicherung',
      schneelr_schneeschutzsystem_select: 'content.options.schneeschutzsystem.schneelr_schneeschutzsystem_select',
      schneelr_schneeschutzsystem_img_r: 'content.options.schneeschutzsystem.schneelr_schneeschutzsystem_img_r',
      schneeschutzsystem: 'safety.schneeschutzsystem',
      protection: 'safety.protection'
    }),
  },
  setup: () => ({v$: useVuelidate()}),
  validations: {
    schneeschutzsystem: {
      required: helpers.withMessage('Ein Schneeschutzsystem muss angegeben werden.', required),
    }
  }
}
</script>
<style scoped>
</style>
