<template>
  <main id="main" class="page-main">
    <tpl-stepper/>
    <div class="block block-form">
      <div class="container">
        <div class="columns columns-4-4 row">
          <div class="column col-10 offset-1 offset-lg-1 col-lg-5 offset-xl-1 col-xl-4">
            <!-- CE Content -->
            <div class="ce-content" v-html="this.schneelr_gebaude"></div>
            <!-- CE Content -->
            <div class="ce-infobox ce-infobox--danger" v-if="v$.$invalid">
              <p>Bitte füllen Sie alle Pflichtfelder aus, um fortzufahren.</p>
            </div>
            <!-- CE Form group -->
            <div class="ce-form-group">
              <div class="form-group-image">
                <div class="form-group-image__image">
                  <img class="img-fluid" src="/assets/svg/pictogram-dachneigung.svg" width="60" height="60" alt=""/>
                </div>
                <div class="form-group" :class="$errorStatus(v$.dachneigung)">
                  <label for="dachneigung-text-input" class="form-label" :class="$errorStatus(v$.dachneigung)">Dachneigung
                    <span class="mandatory" aria-hidden="true">*</span><span
                        class="visually-hidden">(Pflichtfeld)</span></label>
                  <div class="form-input-wrapper form-input-wrapper--with-suffix">
                    <input v-model.trim="v$.dachneigung.$model" :class="$errorStatus(v$.dachneigung)" class="form-input"
                           type="number" id="dachneigung-text-input">
                    <span class="form-input__suffix">
                                            Grad
                                        </span>

                  </div>
                  <p :id="`example-${error.$property}-input-hint`" class="invalid-message"
                     v-for="(error, index) in v$.dachneigung.$errors" :key="index">{{ error.$message }}</p>
                </div>
              </div>
              <div class="form-group-image">
                <div class="form-group-image__image">
                  <img class="img-fluid" src="/assets/svg/pictogram-trauflaenge.svg" width="60" height="60" alt=""/>
                </div>
                <div class="form-group" :class="$errorStatus(v$.trauflaenge)">
                  <label for="trauflaenge-text-input" class="form-label" :class="$errorStatus(v$.trauflaenge)">Trauflänge
                    <span class="mandatory" aria-hidden="true">*</span><span
                        class="visually-hidden">(Pflichtfeld)</span></label>
                  <div class="form-input-wrapper form-input-wrapper--with-suffix">
                    <input v-model.trim="v$.trauflaenge.$model" :class="$errorStatus(v$.trauflaenge)" class="form-input"
                           type="number" id="trauflaenge-text-input">
                    <span class="form-input__suffix">
                                            m
                                        </span>

                  </div>
                  <p :id="`example-${error.$property}-input-hint`" class="invalid-message"
                     v-for="(error, index) in v$.trauflaenge.$errors" :key="index">{{ error.$message }}</p>
                </div>
              </div>
              <div class="form-group-image">
                <div class="form-group-image__image">
                  <img class="img-fluid" src="/assets/svg/pictogram-sparrenlaenge.svg" width="60" height="60" alt=""/>
                </div>
                <div class="form-group" :class="$errorStatus(v$.sparrenlaenge)">
                  <label for="sparrenlaenge-text-input" class="form-label" :class="$errorStatus(v$.sparrenlaenge)">Sparrenlänge
                    <span class="mandatory" aria-hidden="true">*</span><span
                        class="visually-hidden">(Pflichtfeld)</span></label>
                  <div class="form-input-wrapper form-input-wrapper--with-suffix">
                    <input v-model.trim="v$.sparrenlaenge.$model" :class="$errorStatus(v$.sparrenlaenge)"
                           class="form-input" type="number" id="sparrenlaenge-text-input">
                    <span class="form-input__suffix">
                                            m
                                        </span>

                  </div>
                  <p :id="`example-${error.$property}-input-hint`" class="invalid-message"
                     v-for="(error, index) in v$.sparrenlaenge.$errors" :key="index">{{ error.$message }}</p>
                </div>
              </div>
            </div>
            <!-- CE Form group -->
          </div>
          <div class="col-10 offset-1 offset-lg-1 col-lg-5 offset-xl-2 col-xl-4">
            <!-- CE Content -->
            <div class="ce-content" v-html="schneelr_dachziegel"></div>
            <!-- CE Content -->
            <SelectDachziegel/>
          </div>
        </div>
      </div>
    </div>
  </main>
  <tpl-footer active="Objekt" :validation="v$"/>
</template>
<script>
import {mapFields} from 'vuex-map-fields'
import {required, helpers} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import TplStepper from "@/components/TplStepper";
import TplFooter from "@/components/TplFooter";
import SelectDachziegel from "@/components/SelectDachziegel";

export default {
  name: "ViewObjekt",
  components: {TplFooter, TplStepper, SelectDachziegel},
  computed: {
    // The `mapFields` function takes an array of
    // field names and generates corresponding
    // computed properties with getter and setter
    // functions for accessing the Vuex store.
    ...mapFields({
      schneelr_gebaude: 'content.options.objekt.schneelr_gebaude',
      schneelr_dachziegel: 'content.options.objekt.schneelr_dachziegel',
      dachneigung: 'building.dachneigung',
      trauflaenge: 'building.trauflaenge',
      sparrenlaenge: 'building.sparrenlaenge',
      tile: 'tiles.selectedTile'
    }),
  },
  setup: () => ({v$: useVuelidate()}),
  validations: {
    dachneigung: {
      required: helpers.withMessage('Eine Dachneigung muss angegeben werden.', required),
      minValue: helpers.withMessage(
          "Mindestwert für die Dachneigung: 10",
          helpers.withAsync(async value => {
            if (value < 10) {
              return false
            } else {
              return true
            }
          })
      ),
      maxValue: helpers.withMessage(
          "Maximalwert für die Dachneigung: 75",
          helpers.withAsync(async value => {
            if (value > 75) {
              return false
            } else {
              return true
            }
          })
      )
    },
    trauflaenge: {
      required: helpers.withMessage('Eine Trauflänge muss angegeben werden.', required),
      minValue: helpers.withMessage(
          "Mindestwert für die Trauflänge: 1",
          helpers.withAsync(async value => {
            if (value < 1) {
              return false
            } else {
              return true
            }
          })
      ),
      maxValue: helpers.withMessage(
          "Maximalwert für die Trauflänge: 100",
          helpers.withAsync(async value => {
            if (value > 100) {
              return false
            } else {
              return true
            }
          })
      )
    },
    sparrenlaenge: {
      required: helpers.withMessage('Eine Sparrenlänge müssen angegeben werden.', required),
      minValue: helpers.withMessage(
          "Mindestwert für die Sparrenlänge: 2",
          helpers.withAsync(async value => {
            if (value < 2) {
              return false
            } else {
              return true
            }
          })
      ),
      maxValue: helpers.withMessage(
          "Maximalwert für die Sparrenlänge: 20",
          helpers.withAsync(async value => {
            if (value > 20) {
              return false
            } else {
              return true
            }
          })
      )
    },
    tile: {
      required: helpers.withMessage('Ein Dachziegel muss ausgewählt werden.', required)
    }
  },
}
</script>
<style scoped>
</style>
