import { createApp } from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import {storeForm} from "@/store";
import router from "@/router";
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueLoading from 'vue-loading-overlay';
import ValidationStatus from './plugins/ValidationStatus';
import 'vue-loading-overlay/dist/vue-loading.css';
import '../public/assets/builds/bundle.css';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
})

const store = new Vuex.Store({
    modules: {
        storeForm
    },
    plugins: [vuexLocal.plugin]
})

createApp(App).use(store)
    .use(VueAxios, axios)
    .use(VueLoading)
    .use(router)
    .use(ValidationStatus)
    .mount('#app')
