<template>
    <template v-if="isNavigable()">
        <div class="stepper-item stepper-item--completed">
            <router-link :to="{ name: step }" class="step-name">{{ step }}</router-link>
        </div>
    </template>
    <template v-else>
        <div class="stepper-item" :class="{ 'stepper-item--active': isActive()}">
            <div class="step-name">{{ step }}</div>
        </div>
    </template>
</template>

<script>
export default {
    name: "StepperItem",
    props: ['step'],
    data() {
        return {
            steps: {
                Standort: 0,
                Objekt: 1,
                Schneeschutzsystem: 2,
                Ergebnis: 3
            }
        }
    },
    methods: {
        isActive() {
            return this.step === this.$route.name
        },
        isNavigable() {
            return this.steps[this.$route.name] > this.steps[this.step]
        }
    }
}
</script>

<style scoped>

</style>