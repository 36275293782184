<template>
    <main id="main" class="page-main">
        <tpl-stepper />

        <div class="block block-form">
            <div class="container">

                <div class="columns columns-4-4 row">
                    <div class="column col-10 offset-1 offset-lg-1 col-lg-4">

                        <div class="ce-data-overview">
                            <div class="ce-data-overview__header">
                                <h2 class="ce-data-overview__title">Standortdetails</h2>
                                <router-link to="/standort" class="ce-data-overview__edit">
                                    <svg class="icon icon--before" aria-hidden="true"><use xlink:href="#icon-edit"></use></svg><span>Bearbeiten</span>
                                </router-link>
                            </div>
                            <div class="ce-data-overview__body">
                                <dl>
                                    <dt v-if="address.firstname && address.name">Vorname, Name</dt>
                                    <dd v-if="address.firstname && address.name">{{ address.firstname }} {{ address.name }}</dd>
                                    <dt>PLZ, Ort</dt>
                                    <dd>{{ address.plz }}, {{ address.city }}</dd>
                                    <dt v-if="address.address">Straße, Hausnr.</dt>
                                    <dd v-if="address.address">{{ address.address }}</dd>

                                    <template v-for="(item, index) in result?.standortdetails" :key="index">
                                        <dt>{{ item.text }}</dt>
                                        <dd>{{ item.wert }}</dd>
                                    </template>

                                </dl>
                            </div>
                        </div>

                    </div>

                    <div class="col-10 offset-1 offset-lg-2 col-lg-4">

                        <div class="ce-data-overview">
                            <div class="ce-data-overview__header">
                                <h2 class="ce-data-overview__title">Objekt</h2>
                                <router-link to="/objekt" class="ce-data-overview__edit">
                                    <svg class="icon icon--before" aria-hidden="true"><use xlink:href="#icon-edit"></use></svg><span>Bearbeiten</span>
                                </router-link>
                            </div>
                            <div class="ce-data-overview__body">
                                <dl>
                                    <template v-for="(item, index) in result?.objekt" :key="index">
                                        <dt>{{ item.text }}</dt>
                                        <dd>{{ item.wert }}</dd>
                                    </template>
                                </dl>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <div class="block block-result">
            <div class="container">

                <div class="result-overview">
                    <div class="row">
                        <div class="col-10 offset-1 col-lg-10 offset-lg-1">
                            <h2 class="result-overview__title">Ergebnis</h2>
                            <div class="result-overview-data">
                                <dl>
                                    <template v-for="(item, index) in result?.ergebnis" :key="index">
                                        <dt><span class="definition-term">{{ item.text }}</span></dt>
                                        <dd>{{ item.wert }}</dd>
                                    </template>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="result-overview">
                    <div class="row">
                        <div class="col-10 offset-1 col-lg-10 offset-lg-1">
                            <h2 class="result-overview__title">Empfohlenes Zubehör</h2>
                            <div class="result-overview-data result-overview-data--pictograms">
                                <dl>
                                    <template v-for="(item, index) in result?.empfohlenesZubehoer" :key="index">
                                        <dt>
                                            <div class="definition-pictogram" style="  min-width: 55px;">
                                                <img v-if="item.img.url" class="img-fluid" :src="item.img.url" width="60" height="60" alt="" />
                                                <svg v-else class="icon icon--before" aria-hidden="true"><use xlink:href="#icon-warning"></use></svg>
                                            </div>
                                            <span class="definition-term" v-html="item.text">
                                            </span>
                                        </dt>
                                        <dd>{{ item.wert }}</dd>
                                    </template>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="block block-content">
            <div class="container">

                <div class="row">
                    <div class="col-10 offset-1 col-lg-8 offset-lg-2">

                        <div class="content" v-html="this.schneelr_agb_links_text_footer"></div>
                    </div>
                </div>

            </div>
        </div>

    </main>
    <tpl-footer active="Objekt" />

</template>

<script>
import TplFooter from "@/components/TplFooter";
import TplStepper from "@/components/TplStepper";
import {useLoading} from "vue-loading-overlay";
import {mapFields} from "vuex-map-fields";

export default {
    name: "ViewErgebnis",
    components: {TplFooter, TplStepper},
    computed: {
        // The `mapFields` function takes an array of
        // field names and generates corresponding
        // computed properties with getter and setter
        // functions for accessing the Vuex store.
        ...mapFields({
            schneelr_agb_links_text_footer: 'content.options.ergebnis.schneelr_agb_links_text_footer',
            address: 'address',
            conditions: 'conditions',
            building: 'building',
            tiles: 'tiles',
            safety: 'safety',
            result: 'result'
        }),
    },
    async mounted() {
        const $loading = useLoading()
        const loader = $loading.show({
            // Optional parameters
            canCancel: false,
            backgroundColor: '#000',
            color: '#123'
        });

        const calcData= {
            action: 'cl_schneelastrechner_calc',
            plz: this.address.plz,
            dachneigung: this.building.dachneigung,
            sparrenlaenge: this.building.sparrenlaenge,
            trauflaenge: this.building.trauflaenge,
            norddeutscheTiefebene: this.address.northerngerman ? 1 : 0,
            ziegel_id: this.tiles.selectedTile.post_name_id,
            schneeschutzsystem: this.safety.schneeschutzsystem.wert
        }
        const params = new URLSearchParams(calcData);
        const {data} = await this.$http.get(`https://www.dachziegel.de/wp-admin/admin-ajax.php?${params}`);
        this.$store.state.storeForm.result = data.data;
        loader.hide()
    }
}
</script>

<style scoped>

</style>
