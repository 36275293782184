import { getField, updateField } from 'vuex-map-fields';

export const storeForm = {
    state: {
        // We add one empty address to
        // render the first form row.
        address: {
            plz: null,
            city: null,
            address: null,
            firstname: null,
            name: null,
            northerngerman: false,
        },
        conditions: {
            hoehe: '-',
            schneelast: '-',
            schneelastzone: '-'
        },
        building: {
            dachneigung: null,
            trauflaenge: null,
            sparrenlaenge: null
        },
        tiles: {
            selectedTile: null
        },
        safety: {
            schneeschutzsystem: null,
            protection: null
        },

        content: null,
        result: null
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },
}
