<template>
    <div class="block block-progress">
        <div class="container">
            <div class="stepper">
                <div class="row">
                    <div class="col-12 col-lg-10 offset-lg-1">
                        <div class="stepper__inner">
                            <stepper-item v-for="(step, index) in steps" :key="index" :step="step"></stepper-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StepperItem from "@/components/StepperItem";

export default {
    name: "TplStepper",
    components: { StepperItem },
    data() {
        return {
            steps: [
                'Standort',
                'Objekt',
                'Schneeschutzsystem',
                'Ergebnis',
            ]
        }
    },
    methods: {
        isActive(stepItem) {
            return stepItem === this.$route.name
        }
    }
}
</script>

<style scoped>

</style>