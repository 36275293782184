<template>

    <div class="page">

        <header class="page-header sticky-top">
            <div class="container">
                <div class="page-header__inner">
                    <div class="brand">
                        <a href="/">
                            <img src="/assets/svg/logo-jacobi-walther-dachziegel.svg" width="180" alt="" />
                        </a>
                    </div>
                    <div class="page-header-title">
                        <h1 class="page-header-title__title">Rechner für Schneeschutzsysteme</h1>
                    </div>
                </div>
            </div>
        </header>

        <router-view />

        <svg aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <symbol id="icon-check" viewBox="0 0 32 32">
                    <path d="M26.4 7.2l-14.72 15.040-6.080-6.080-1.6 1.6 7.68 8 16.32-16.96z"></path>
                </symbol>
                <symbol id="icon-warning" viewBox="0 0 32 32">
                    <path d="M14.72 12.8h2.4v6.4h-2.4v-6.4z"></path>
                    <path d="M16 2.4l-14.88 26.080h29.76l-14.88-26.080zM16 7.2l10.72 18.72h-21.44l10.72-18.72z"></path>
                    <path d="M16 24c0.96 0 1.6-0.64 1.6-1.6s-0.64-1.6-1.6-1.6-1.6 0.64-1.6 1.6 0.64 1.6 1.6 1.6z"></path>
                </symbol>
                <symbol id="icon-download" viewBox="0 0 32 32">
                    <path d="M29.92 29.28h-27.2v-2.4h12.8l-8.32-8.48 1.6-1.6 5.92 5.92v-19.52h2.56v19.52l5.92-5.92 1.6 1.6-8.32 8.48h13.44z"></path>
                </symbol>
                <symbol id="icon-edit" viewBox="0 0 32 32">
                    <path d="M28.32 6.080l-3.36-3.36c-0.64-0.64-1.6-0.64-2.24 0l-19.68 19.52-0.16 5.92 5.92-0.16 19.68-19.68c0.48-0.48 0.48-1.6-0.16-2.24zM19.84 8.96l2.24 2.24-13.44 13.44-2.24-2.24 13.44-13.44zM23.84 9.44l-2.24-2.24 2.24-2.24 2.24 2.24-2.24 2.24z"></path>
                </symbol>
                <symbol id="icon-chevron-down" viewBox="0 0 32 32">
                    <path d="M16 22.56l-10.4-10.56 1.6-1.6 8.8 8.64 8.8-8.64 1.6 1.6z"></path>
                </symbol>
                <symbol id="icon-arrow-left" viewBox="0 0 34 32">
                    <path d="M12.794 26.4l1.6-1.6-7.52-7.52h22.72v-2.4h-22.72l7.52-7.68-1.6-1.6-10.56 10.4z"></path>
                </symbol>
                <symbol id="icon-arrow-right" viewBox="0 0 34 32">
                    <path d="M20.8 26.4l-1.6-1.6 7.52-7.52h-22.72v-2.4h22.72l-7.52-7.68 1.6-1.6 10.56 10.4z"></path>
                </symbol>
            </defs>
        </svg>
    </div>

</template>

<script>

export default {
    name: 'App',
    components: {

    },
}
</script>
