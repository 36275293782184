<template>
    <div class="block block-intro">
        <div class="container">

            <div class="intro-image">
                <div class="row">
                    <div class="col-12 col-lg-10 offset-lg-1">
                        <img class="img-fluid" :src="schneelr_home_intro_img.url"
                             :srcset="`${schneelr_home_intro_img.url}, ${schneelr_home_intro_img.urlx2} 2x`"
                             :width="schneelr_home_intro_img.width" :height="schneelr_home_intro_img.height"
                             v-if="schneelr_home_intro" alt=""/>
                    </div>
                </div>
            </div>
            <div class="intro-content">
                <div class="row">
                    <div class="col-10 offset-1 col-lg-8 offset-lg-2">
                        <div class="intro-content__inner">

                            <div class="intro-content__content" v-html="schneelr_home_intro"></div>

                            <form class="intro-content__confirm-form">

                                <div class="ce-infobox ce-infobox--danger" v-if="showConfirmationError">
                                    <p>Bitte füllen Sie alle Pflichtfelder aus, um fortzufahren.</p>
                                </div>

                                <div class="form-group">
                                    <div class="checkboxes">
                                        <div class="checkboxes__item">
                                            <input type="checkbox" class="checkboxes__input" :class="getErrorClass()" id="checkbox-example-1"
                                                   name="checkbox-example" value="checkbox-value-1" required
                                                   v-model="confirmation"
                                            >
                                            <label class="label checkboxes__label" :class="getErrorClass()" for="checkbox-example-1"
                                                   v-html="schneelr_home_agb"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <router-link to="/standort" class="button button--primary" v-html="schneelr_home_button" v-if="confirmation"></router-link>
                                    <div class="button button--primary" v-html="schneelr_home_button" @click="checkConfirmation" v-else></div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useLoading} from "vue-loading-overlay";

export default {
    name: "ViewHome",
    data() {
        return {
            schneelr_home_intro_img: {},
            schneelr_home_intro: '',
            schneelr_home_agb: '',
            schneelr_home_button: '',
            confirmation: false,
            showConfirmationError: false
        }
    },
    methods: {
        addIcon(text) {
            return text + '<svg class="icon icon--after" aria-hidden="true">\n' +
                '                                            <use xlink:href="#icon-arrow-right"></use>\n' +
                '                                        </svg>';
        },
        getErrorClass() {
            return this.showConfirmationError ? {  'is-invalid': true } : { 'is-invalid': false }
        },
        checkConfirmation() {
            this.showConfirmationError = !this.confirmation
        }
    },
    watch: {
        confirmation(newValue) {
            this.showConfirmationError = !newValue
        }
    },
    async mounted() {
        const $loading = useLoading()
        const loader = $loading.show({
            // Optional parameters
            canCancel: false,
            backgroundColor: '#000',
            color: '#123'
        });

        window.localStorage.clear();

        const {data} = await this.$http.get('https://www.dachziegel.de/wp-admin/admin-ajax.php?action=cl_schneelastrechner');
        this.$store.state.storeForm.content = data.data;

        loader.hide()
        this.schneelr_home_intro_img = data.data.options.home.schneelr_home_intro_img;
        this.schneelr_home_intro = data.data.options.home.schneelr_home_intro;
        this.schneelr_home_agb = data.data.options.home.schneelr_home_agb;
        this.schneelr_home_button = this.addIcon(data.data.options.home.schneelr_home_button);
    }
}
</script>

