<template>
  <footer class="page-footer fixed-bottom">
    <div class="container">
      <div class="footer-controls">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="footer-controls__inner">
              <router-link :to="getBackLink()" class="button button--primary">
                <svg class="icon icon--before" aria-hidden="true">
                  <use xlink:href="#icon-arrow-left"></use>
                </svg>
                Zurück
              </router-link>
              <div class="button button--primary" v-if="validation?.$invalid" v-html="getForwardText()">
              </div>
              <router-link v-else-if="this.$route.name !== 'Ergebnis'" :to="getForwardLink()"
                           class="button button--primary" v-html="getForwardText()">
              </router-link>
              <a @click="getPDF()" @click.prevent class="button button--primary" href="#" v-else>Ergebnis als PDF speichern</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import {mapFields} from "vuex-map-fields";
import {useLoading} from "vue-loading-overlay";

export default {
  name: "TplFooter",
  props: [
    'validation'
  ],
  computed: {
    // The `mapFields` function takes an array of
    // field names and generates corresponding
    // computed properties with getter and setter
    // functions for accessing the Vuex store.
    ...mapFields({
      schneelr_agb_links_text_footer: 'content.options.ergebnis.schneelr_agb_links_text_footer',
      address: 'address',
      conditions: 'conditions',
      building: 'building',
      tiles: 'tiles',
      safety: 'safety',
      result: 'result'
    }),
  },
  methods: {
    getPDF() {
      const $loading = useLoading()
      const loader = $loading.show({
        // Optional parameters
        canCancel: false,
        backgroundColor: '#000',
        color: '#123'
      });
      const calcData = {
        action: 'cl_schneelastrechner_pdf',
        address: JSON.stringify(this.address),
        dachneigung: this.building.dachneigung,
        sparrenlaenge: this.building.sparrenlaenge,
        trauflaenge: this.building.trauflaenge,
        norddeutscheTiefebene: this.address.northerngerman ? 1 : 0,
        ziegel_id: this.tiles.selectedTile.post_name_id,
        schneeschutzsystem: this.safety.schneeschutzsystem.wert
      }
      const params = new URLSearchParams(calcData);
      this.$http.get(`https://www.dachziegel.de/wp-admin/admin-ajax.php?${params}`, {
        responseType: 'blob',
      })
          .then(response => {
            var blob = new Blob([response.data]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Jacobi_Schneelastberechnung.pdf";
            link.click();
            loader.hide()
          })
          .catch(e => {
            console.log(e);
          });


      return false;
    },
    getBackLink() {
      switch (this.$route.name) {
        case 'Standort':
          return '/';
        case 'Objekt':
          return '/standort';
        case 'Schneeschutzsystem':
          return '/objekt';
        case 'Ergebnis':
          return '/schneeschutzsystem';
      }
    },
    getForwardLink() {
      switch (this.$route.name) {
        case 'Standort':
          return '/objekt';
        case 'Objekt':
          return '/schneeschutzsystem';
        case 'Schneeschutzsystem':
          return '/ergebnis';
        case 'Ergebnis':
          return '/pdf';
      }
    },
    getForwardText() {
      switch (this.$route.name) {
        case 'Standort':
        case 'Objekt':
          return 'Weiter<svg class="icon icon--after" aria-hidden="true"><use xlink:href="#icon-arrow-right"></use></svg>'
        case 'Schneeschutzsystem':
          return 'zum Ergebnis<svg class="icon icon--after" aria-hidden="true"><use xlink:href="#icon-arrow-right"></use></svg>'
        case 'Ergebnis':
          return '<svg class="icon icon--before" aria-hidden="true"><use xlink:href="#icon-download"></use></svg>Ergebnis als PDF speichern'
      }
    }
  }
}
</script>
<style scoped>
</style>
