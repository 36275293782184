// plugins/i18n.js
export default {
    install: (app) => {
        app.config.globalProperties.$errorStatus = (validation) => {
            return {
                'is-invalid': validation.$error
            }

        }
    }
}