<template>
    <main id="main" class="page-main">
        <tpl-stepper />

        <div class="block block-form">
            <div class="container">

                <div class="columns columns-4-4 row">
                    <div class="column col-10 offset-1 offset-lg-1 col-lg-5 offset-xl-1 col-xl-4">

                        <!-- CE Content -->
                        <div class="ce-content" v-html="schneelr_standortdetails"></div>
                        <!-- CE Content -->

                        <div class="ce-infobox ce-infobox--danger" v-if="v$.$invalid">
                            <p>Bitte füllen Sie alle Pflichtfelder aus, um fortzufahren.</p>
                        </div>

                        <!-- CE Form group -->
                        <div class="ce-form-group">
                            <div class="row">
                                <div class="col-12 col-lg-3">
                                    <div class="form-group" :class="$errorStatus(v$.plz)">
                                        <label for="plz-text-input" class="form-label" :class="$errorStatus(v$.plz)">PLZ <span class="mandatory" aria-hidden="true">*</span><span class="visually-hidden">(Pflichtfeld)</span></label>
                                        <div class="form-input-wrapper">
                                            <input v-model.trim="v$.plz.$model" :class="$errorStatus(v$.plz)" class="form-input" type="text" id="plz-text-input" aria-describedby="example-plz-input-hint">
                                            <p :id="`example-${error.$property}-input-hint`" class="invalid-message" v-for="(error, index) in v$.plz.$errors" :key="index">{{ error.$message }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-9">
                                    <div class="form-group" :class="$errorStatus(v$.city)">
                                        <label for="city-text-input" class="form-label" :class="$errorStatus(v$.city)">Ort <span class="mandatory" aria-hidden="true">*</span><span class="visually-hidden">(Pflichtfeld)</span></label>
                                        <div class="form-input-wrapper">
                                            <input v-model.trim="v$.city.$model" :class="$errorStatus(v$.city)" class="form-input" type="text" id="city-text-input" aria-describedby="example-city-input-hint">
                                            <p :id="`example-${error.$property}-input-hint`" class="invalid-message" v-for="(error, index) in v$.city.$errors" :key="index">{{ error.$message }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group" :class="$errorStatus(v$.address)">
                                        <label for="address-text-input" class="form-label" :class="$errorStatus(v$.address)">Straße, Hausnummer</label>
                                        <div class="form-input-wrapper">
                                            <input v-model.trim="v$.address.$model" :class="$errorStatus(v$.address)" class="form-input" type="text" id="address-text-input" aria-describedby="example-address-input-hint">
                                            <p :id="`example-${error.$property}-input-hint`" class="invalid-message" v-for="(error, index) in v$.address.$errors" :key="index">{{ error.$message }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group" :class="$errorStatus(v$.firstname)">
                                        <label for="firstname-text-input" class="form-label" :class="$errorStatus(v$.firstname)">Vorname</label>
                                        <div class="form-input-wrapper">
                                            <input v-model.trim="v$.firstname.$model" :class="$errorStatus(v$.firstname)" class="form-input" type="text" id="firstname-text-input" aria-describedby="example-firstname-input-hint">
                                            <p :id="`example-${error.$property}-input-hint`" class="invalid-message" v-for="(error, index) in v$.firstname.$errors" :key="index">{{ error.$message }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="form-group" :class="$errorStatus(v$.name)">
                                        <label for="name-text-input" class="form-label" :class="$errorStatus(v$.name)">Nachname</label>
                                        <div class="form-input-wrapper">
                                            <input v-model.trim="v$.name.$model" :class="$errorStatus(v$.name)"  class="form-input" type="text" id="name-text-input" aria-describedby="example-name-input-hint">
                                            <p :id="`example-${error.$property}-input-hint`" class="invalid-message" v-for="(error, index) in v$.name.$errors" :key="index">{{ error.$message }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-checkboxes">
                                        <div class="checkboxes">
                                            <div class="checkboxes__item">
                                                <input v-model="v$.northerngerman.$model" class="checkboxes__input" id="checkbox-example-1" name="checkbox-example" type="checkbox" value="checkbox-value-1">
                                                <label class="form-label checkboxes__label" for="checkbox-example-1">
                                                    Norddeutsche Tiefebene
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- CE Form group -->

                    </div>

                    <div class="col-10 offset-1 offset-lg-1 col-lg-5 offset-xl-2 col-xl-4">

                        <!-- CE Content -->
                        <div class="ce-content" v-html="schneelr_bedingungen"></div>
                        <!-- CE Content -->


                        <!-- CE Infobox
                        <div class="ce-infobox ce-infobox--warning" v-html="schneelr_bemerkung_hinweis"></div>
                         CE Infobox -->


                        <!-- CE Parameter Select -->
                        <div class="ce-parameter-select">
                            <div class="ce-parameter-select__inner row">

                                <div class="col">
                                    <div class="dl-horizontal__element">
                                        <dt>Höhe über NN</dt>
                                        <dd>{{ hoehe }}</dd>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="dl-horizontal__element">
                                        <dt>Schneelast</dt>
                                        <dd>{{ schneelast }}</dd>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <div class="dl-horizontal__element">
                                            <dt>Zone</dt>
                                            <dd>{{ schneelastzone }}</dd>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- CE Parameter Select -->


                        <!-- CE Image -->
                        <div class="ce-image ce-image--center">
                            <img class="img-fluid" :src="this.zoneImg.url" :srcset="`${ this.zoneImg.url } 1x, ${ this.zoneImg.urlx2 } 2x`" width="250" height="354" alt="" />
                        </div>
                        <!-- CE Image -->

                    </div>
                </div>

            </div>
        </div>
    </main>

    <tpl-footer active="Standort" :validation="v$" />

</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { required, numeric, minLength, maxLength, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import TplStepper from "@/components/TplStepper";
import TplFooter from "@/components/TplFooter";
// import MyFormField from "@/components/MyFormField";

export default {
    name: "ViewStandort",
    components: { TplFooter, TplStepper},
    computed: {
        // The `mapFields` function takes an array of
        // field names and generates corresponding
        // computed properties with getter and setter
        // functions for accessing the Vuex store.
        ...mapFields({
            schneelr_standortdetails: 'content.options.standort.schneelr_standortdetails',
            schneelr_bedingungen: 'content.options.standort.schneelr_bedingungen',
            schneelr_bemerkung_hinweis: 'content.options.standort.schneelr_bemerkung_hinweis',
            schneelr_bedingungen_karte_img: 'content.options.standort.schneelr_bedingungen_karte_img',
            schneelr_bedingungen_schneelastzonen: 'content.options.standort.schneelr_bedingungen_schneelastzonen',
            zoneImg: 'content.options.standort.schneelr_bedingungen_karte_img',
            plz: 'address.plz',
            city: 'address.city',
            address: 'address.address',
            firstname: 'address.firstname',
            name: 'address.name',
            northerngerman: 'address.northerngerman',
            hoehe: 'conditions.hoehe',
            schneelast: 'conditions.schneelast',
            schneelastzone: 'conditions.schneelastzone',
        }),
    },
    data() {
        return {
            vuelidateExternalResults: {
                plz: []
            }
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    validations: {
        plz: {
            required: helpers.withMessage('Eine PLZ muss angegeben werden.', required),
            numeric: helpers.withMessage('Eine PLZ kann nur aus Zahlen bestehen', numeric),
            minLength: helpers.withMessage('Eine PLZ muss aus 5 Zahlen bestehen', minLength(5)),
            maxLength: helpers.withMessage('Eine PLZ muss aus 5 Zahlen bestehen', maxLength(5))
        },
        city: {
            required: helpers.withMessage('Ein Ort muss angegeben werden.', required)
        },
        address: {
            // required: helpers.withMessage('Straße und Hausnummer müssen angegeben werden.', required)
        },
        firstname: {
            // required: helpers.withMessage('Ein Vorname muss angegeben werden.', required)
        },
        name: {
            // required: helpers.withMessage('Ein Nachname muss angegeben werden.', required)
        },
        northerngerman: {

        }
    },
    watch: {
        async plz(newPlz) {
            if(!this.v$.plz.$invalid) {
                try {
                    const { data } = await this.$http.get(`https://www.dachziegel.de/wp-admin/admin-ajax.php?action=cl_schneelastrechner_zone&plz=${newPlz}`);
                    this.hoehe = data.data.HOEHE + ' m';
                    this.schneelast = data.data.SCHNEELAST_WERT + ' kN/m²';
                    this.schneelastzone = data.data.SCHNEELAST;
                } catch(error) {
                    const errors = {
                        plz: ['Die angegebene PLZ ist ungültig']
                    }

                    Object.assign(this.vuelidateExternalResults, errors)
                }
            } else {
                this.hoehe = '-';
                this.schneelast = '-';
                this.schneelastzone = '-';
            }
        }
    }
}
</script>
<style scoped>
</style>
